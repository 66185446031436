import { FilterComponent } from '@asaprint/asap/components/tables/interfaces.js';
import SelectFilter from '@engined/client/components/table/SelectFilter.js';
import { StringFilter as SchemaStringFilter } from '@asaprint/asap/schema.client.types.js';
import { statusOptions } from '@asaprint/common/constants/Product.js';
import React from 'react';

const ProductStatusFilter: FilterComponent<any, SchemaStringFilter> = (props) => {
  return <SelectFilter {...props} options={statusOptions} />;
};

ProductStatusFilter.displayName = 'ProductStatusFilter';

ProductStatusFilter.filterToGraphQLVariable = (value) => {
  return {
    eq: value,
  };
};

export default React.memo(ProductStatusFilter);

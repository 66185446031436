import useQuery from '@engined/client/hooks/useQuery.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import {
  GraphQLReactTable as TGraphQLReactTable,
  GraphQLReactTableProps,
} from '@asaprint/asap/components/ReactTable.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import DateCell from '@asaprint/asap/components/tables/DateCell.js';
import DateFilter from '@asaprint/asap/components/tables/DateFilter.js';
import ProductStatusCell from '@asaprint/asap/components/tables/ProductStatusCell.js';
import ProductStatusFilter from '@asaprint/asap/components/tables/ProductStatusFilter.js';
import UserCell from '@asaprint/asap/components/tables/UserCell.js';
import UserFilter from '@asaprint/asap/components/tables/UserFilter.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE, ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import { Products_Load, Products_LoadTable } from '@asaprint/asap/routes/__authenticated/products/index.graphql';
import {
  ProductFilter,
  Products_LoadQuery,
  Products_LoadQueryVariables,
  Products_LoadTableQuery,
  Products_ProductFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import Loading from '@engined/client/components/Loading.js';
import SelectFilter, { Options } from '@engined/client/components/table/SelectFilter.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { url } from '@engined/core/services/routes.js';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Row, TableState } from 'react-table';

interface OwnProps {}

type Props = OwnProps;

const emptyArray = [];
const mapData = (data: Products_LoadTableQuery) => data?.products?.edges.map((e) => e.node) || emptyArray;
const mapCount = (data: Products_LoadTableQuery) => data?.products?.pageInfo.count || 0;
const defaultSortBy: TableState['sortBy'] = [{ id: 'createdAt', desc: true }];

const GraphQLReactTable = TGraphQLReactTable as unknown as React.NamedExoticComponent<
  GraphQLReactTableProps<Products_ProductFragment>
>;

const Page: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const { data, error, loading } = useQuery<Products_LoadQuery, Products_LoadQueryVariables>(Products_Load);

  const phasesEdges = data?.phaseGroups?.edges;
  const phasesOptions = useMemo<Options>(
    () =>
      phasesEdges?.reduce<Options>((acc, cur) => {
        acc.push({
          label: cur.node.name,
          options: cur.node.phases.map((phase) => ({
            value: phase.id,
            label: phase.name,
          })),
        });
        return acc;
      }, []) || emptyArray,
    [phasesEdges],
  );

  const onRowClick = useEventCallback((row: Row<Products_ProductFragment>) => {
    navigate(
      `${url(ORDERS_RECEIVED_SHOW_ROUTE, { id: row.original.orderReceivedId.toLowerCase() })}#product-${
        row.original.id
      }`,
    );
  });

  const columns: Column<Products_ProductFragment>[] = useMemo(
    () => [
      {
        Header: 'Číslo dokladu',
        id: 'orderReceived.moneyNumber',
        accessor: (row) => row.orderReceived.moneyNumber,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            <strong>
              {value}-{original.position}
            </strong>
          </CenteredCell>
        ),
        maxWidth: 120,
        width: 120,
        sortByToGraphQLVariable: () => ['orderReceived.moneyNumber', 'position'],
        filterToGraphQLVariable: (value, language, filter) => {
          const parts = value.split('-');
          if (parts.length > 1 && /\d+/.test(parts[1])) {
            filter.position = { eq: parseInt(parts[1], 10) };
          }

          return {
            ilike: `%${parts[0]}%`,
          };
        },
      },
      {
        Header: 'Odberateľ - firma',
        id: 'orderReceived.moneyAddressName',
        accessor: (row) => row.orderReceived.moneyAddressName,
        width: 159,
      },
      {
        Header: 'Popis',
        accessor: 'name',
        width: 500,
      },
      {
        Header: 'Zodpovedný account',
        id: 'orderReceived.assignedTo',
        accessor: (row) => row.orderReceived.assignedTo,
        width: 120,
        Cell: UserCell,
        Filter: UserFilter,
        sortByToGraphQLVariable: () => 'orderReceived.assignedTo.fullName',
      },
      {
        Header: 'Dátum prijatia',
        id: 'createdAt',
        accessor: 'createdAt',
        Cell: DateCell,
        Filter: DateFilter,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Dátum expedície',
        id: 'orderReceived.expeditionAt',
        accessor: (row) => row.orderReceived.expeditionAt,
        Cell: DateCell,
        Filter: DateFilter,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Dátum montáže',
        id: 'orderReceived.assemblyAt',
        accessor: (row) => row.orderReceived.assemblyAt,
        Cell: DateCell,
        Filter: DateFilter,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Dátum obhliadky',
        id: 'orderReceived.inspectionAt',
        accessor: (row) => row.orderReceived.inspectionAt,
        Cell: DateCell,
        Filter: DateFilter,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Fázy výroby',
        accessor: 'phase',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) =>
          value ? (
            <Box
              sx={{
                textAlign: 'center',
                borderRadius: '1em',
                mx: 1,
                px: 2,
                width: 'calc(100% - 10px)',
                backgroundColor: `#${value.color}`,
                color: (theme: Theme) => theme.palette.getContrastText(`#${value.color}`),
              }}
            >
              {value.name}
            </Box>
          ) : null,
        // eslint-disable-next-line react/display-name
        Filter: (props) => <SelectFilter {...props} options={phasesOptions} />,
        filterToGraphQLVariable: (value: string): ProductFilter['phase'] => ({
          id: { eq: value },
        }),
        width: 150,
      },
      {
        Header: 'Stav fázy výroby',
        accessor: 'status',
        Cell: ProductStatusCell,
        Filter: ProductStatusFilter,
        width: 150,
      },
    ],
    [phasesOptions],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faCommentsDollar} /> Produkty objednávok prijatých
          </>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <GraphQLReactTable
            id="products"
            columns={columns}
            defaultSortBy={defaultSortBy}
            query={Products_LoadTable}
            mapCount={mapCount}
            mapData={mapData}
            onRowClick={onRowClick}
            canPrint={false}
            canXLSXExport={false}
          />
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.ProductsRead)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({ query: Products_Load });
    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Produkty',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Produkty | ${meta.title}`,
  }),
};
